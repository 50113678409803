import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { JoinCivicAllianceButton } from "../components/Buttons";
import { Grid } from "semantic-ui-react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { knuthShuffle, getRandomInt } from "../helpers/helperfns";
import { 
  ExecutiveName,
  ExecutiveTitle,
  HeaderH1,
  LogoHiddenLabel,
  SectionH1
} from "../helpers/styledComponents";
import { 
  CommitmentType, 
  iCompanyCommitmentProps,
  iCommitmentInterpreterProps,
  iCommitmentsSectionProps
} from "../helpers/types";
import styled from "styled-components";

const headerContent = (
  <HeaderH1 style={{marginBottom: ".5em", paddingBottom: "20px" }}>
    We're <b style={{fontSize: "1.6em"}}>100% In</b> for democracy
  </HeaderH1>
);


const StyledImageWrapper = styled.div`
  .gatsby-image-wrapper {
    div {
      padding-bottom: 0 !important;
    }

    picture {
      img {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        width: auto !important;
        max-height: 40px !important;
      }
    }
  }
  image: {
    width: auto;
  }
`;

const getTextNodes:any | undefined = ( datum: CommitmentType ) => {
  if (!datum) return '';
  return datum.nodeType === "paragraph" 
    ? datum.content
    : getTextNodes(datum.content);
}

const CommitmentInterpreter:React.FC<iCommitmentInterpreterProps> = ({ committmentObj }) => {
  const textArray:Array<CommitmentType[]> = [];
  
  committmentObj?.content?.forEach(datum => textArray.push(getTextNodes(datum)));

  const textJSX = textArray.map((datum, i) => {
    if (!datum) return [];
    return datum.map((textObj, j) => {
      let returnJSX:JSX.Element = <span key={`${i}${j}`}>{textObj.value}</span>;  
      textObj?.marks?.forEach(mark => {
        if (mark.type === "bold") returnJSX = <b key={`${j}bold`}>{returnJSX}</b>;
        if (mark.type === "italics") returnJSX = <i key={`${j}italics`}>{returnJSX}</i>;
      });
      return returnJSX;
    });
  });

  return <div style={{ marginBottom: "3px" }}>"{textJSX}"</div>;
};

const CompanyCommittment:React.FC<iCompanyCommitmentProps> = ({ commitment, executiveName, executiveTitle, id, logo, name}) => (
  <Grid.Column key={id} computer={8} mobile={16} tablet={8} style={{ padding: ".75rem", marginBottom: "50px" }}>
    <Grid>
      <Grid.Column width={4} textAlign="center" style={{ padding: ".75rem" }} title={name}>
        <StyledImageWrapper>
          <Img fluid={logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
        </StyledImageWrapper>
      </Grid.Column>
      <Grid.Column width={12}>
        <CommitmentInterpreter committmentObj={JSON.parse(commitment?.raw || "")} />
        <ExecutiveName>{executiveName}</ExecutiveName>
        <ExecutiveTitle>{executiveTitle}</ExecutiveTitle>
        <LogoHiddenLabel>{name}</LogoHiddenLabel>
      </Grid.Column>
    </Grid>
  </Grid.Column>
);

const CommitmentsSection:React.FC<iCommitmentsSectionProps> = ({companies}) => {
  let companiesA = 
    knuthShuffle(
      companies.filter(company => company.commitments.includes("100% in For Democracy w/ Commitment") && company.commitmentLevel === 'A')
    ).map(company => <CompanyCommittment key={company.id} {...company}/>);

  let companiesB = 
    knuthShuffle(
      companies.filter(company => company.commitments.includes("100% in For Democracy w/ Commitment") && company.commitmentLevel === 'B')
    ).map(company => <CompanyCommittment key={company.id} {...company}/>);

  let companiesC = 
    knuthShuffle(
      companies.filter(company => 
        company.commitments.includes("100% in For Democracy w/ Commitment") 
        && company.commitmentLevel === 'C' 
        && !['Solve', 'Neu'].includes(company.name)
      )
    ).map(company => <CompanyCommittment key={company.id} {...company}/>);

  let Neu = companies.find(company => company.name === "Neu");
  let Solve = companies.find(company => company.name === "Solve");

  let randomIndex = getRandomInt(companiesC.length);

  companiesC.splice(
    randomIndex, 
    0,
    (
      <Grid.Column key={Neu.id} computer={8} mobile={16} tablet={8} style={{ padding: ".75rem", marginBottom: "50px" }}>
        <Grid>
          <Grid.Column width={4} textAlign="center" style={{ padding: ".75rem" }} title={Neu.name}>
          <StyledImageWrapper>
            <Img fluid={Neu?.logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
          </StyledImageWrapper>
          <StyledImageWrapper>
            <Img fluid={Solve?.logo.fluid} style={{ maxHeight: "40px", width: "auto" }} />
          </StyledImageWrapper>
          </Grid.Column>
          <Grid.Column width={12}>
            <CommitmentInterpreter committmentObj={JSON.parse(Neu?.commitment?.raw || "")} />
            <ExecutiveName>{Neu?.executiveName}</ExecutiveName>
            <ExecutiveTitle>{Neu?.executiveTitle}</ExecutiveTitle>
            <LogoHiddenLabel>{Neu?.name}</LogoHiddenLabel>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    )
  )

  return <>{[...companiesA, ...companiesB, ...companiesC]}</>;
}

export default ({ data }) => {
  return (
    <Layout mode="dark" headerContent={headerContent} title="We're 100% in for Democracy | Civic Alliance">
      <Section mode="light" style={{ paddingTop: "5em", paddingBottom: "0"}}>
        <SectionH1>Each of our member companies has unique values. Yet, we are united by these shared beliefs: Every American has a voice in our democracy. Voting should be safe and accessible to all. Elections must be fair and transparent.</SectionH1>
        <SectionH1>As business leaders, we’re committed to strengthening our democracy by encouraging nonpartisan voter participation. Here are unique ways each of us is activating our companies and communities.</SectionH1>
      </Section>
      <Section mode="light" larger relaxedPadding>
        <Grid style={{ margin: "-.75rem" }}>
          <CommitmentsSection companies={data.allContentfulCivicAllianceCompany.nodes} />
        </Grid>
      </Section>
      <Section mode="dark" larger>
        <SectionH1>
          It is time to take decisive action to empower voters, build trust in our elections, and support our communities. These actions aren't partisan or political. They're patriotic.
        </SectionH1>
      </Section>
      <Section mode="light" larger>
        <SectionH1 style={{ marginBottom: "1.5rem" }}>
          We invite you to join our Civic Alliance and commit to being 100% In for democracy.
        </SectionH1>
        <JoinCivicAllianceButton mode="dark" center larger/>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulCivicAllianceCompany(filter: {commitment: {raw: {ne: null}}, commitments: {in: ["100% in For Democracy w/ Commitment", "100% In For Democracy"]}, logo: {file: {url: {ne: null}}}}) {
      nodes {
        name
        id
        commitment {
          raw
        }
        commitmentLevel
        executiveName
        executiveTitle
        commitments
        logo {
          file {
            fileName
            url
          }
          fluid(maxWidth: 140) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;